import { createSlice } from '@reduxjs/toolkit';
import { sessionApi } from '../session';
import { useProvideTags } from '../../utils/hooks/useProvideTags';

export const personalReportsSlice = createSlice({
  name: 'personal_reports',
  initialState: {},
});

sessionApi.injectEndpoints({
  endpoints: (builder) => ({
    getPersonalReportsTable: builder.query({
      query: (data) => `/personal_reports${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['GET_TABLE_PERSONAL_REPORTS']),
    }),
    getPersonalReport: builder.query({
      query: (id) => `/personal_reports/${id}`,
      providesTags: (result, error) => useProvideTags(result, error, ['GET_PERSONAL_REPORT']),
    }),
    sendPersonalReport: builder.mutation({
      query: (data) => ({
        url: '/personal_reports',
        body: data,
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: () => (['GET_TABLE_PERSONAL_REPORTS']),
    }),
    updatePersonalReport: builder.mutation({
      query: ({ id, data }) => ({
        url: `/personal_reports/${id}`,
        body: data,
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: () => (['GET_TABLE_PERSONAL_REPORTS', 'GET_PERSONAL_REPORT']),
    }),
    destroyPersonalReport: builder.mutation({
      query: (id) => ({
        url: `/personal_reports/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: () => (['GET_TABLE_PERSONAL_REPORTS']),
    }),
    convertPersonalCurrencies: builder.mutation({
      query: ({ data }) => ({
        url: '/currency-convert',
        body: data,
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error)
    }),
    getPersonalReportFiles: builder.mutation({
      query: ({ id }) => ({
        url: `/personal_reports/files/list/${id}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    openPersonalReportFile: builder.mutation({
      query: ({ id }) => ({
        url: `/personal_reports/files/open/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFinancePilotReportsTable: builder.query({
      query: (data) => `/personal_reports/finance/pilots_table${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['GET_TABLE_PERSONAL_REPORTS_PILOTS']),
    }),
    getFinanceCrewProfile: builder.query({
      query: (id) => `/personal_reports/finance/pilots/${id}`,
      providesTags: (result, error) => useProvideTags(result, error, ['GET_CREW_PROFILE']),
    }),
    getFinanceCrewReportsTable: builder.query({
      query: (data) => `/personal_reports/finance/pilot_reports${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['GET_TABLE_PERSONAL_REPORTS_PILOT']),
    }),
    updatePersonalReportFinance: builder.mutation({
      query: ({ id, data }) => ({
        url: `/personal_reports/finance/update_report/${id}`,
        body: data,
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: () => (['GET_PERSONAL_REPORT']),
    }),
    acceptPersonalReportFinance: builder.mutation({
      query: ({ id, data }) => ({
        url: `/personal_reports/finance/accept_report/${id}`,
        body: data,
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: () => (['GET_PERSONAL_REPORT', 'GET_TABLE_PERSONAL_REPORTS']),
    }),
    getFinanceCrewCompensationsTable: builder.query({
      query: (data) => `/personal_reports/finance/pilot_compensations${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['GET_TABLE_COMPENSATION_PILOT']),
    }),
    destroyCompensation: builder.mutation({
      query: (id) => ({
        url: `/personal_reports/finance/pilot_compensation/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: () => (['GET_TABLE_COMPENSATION_PILOT', 'SEARCH_COMPENSATION']),
    }),
    sendCompensation: builder.mutation({
      query: (data) => ({
        url: '/personal_reports/finance/pilot_compensation',
        body: data,
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: () => (['GET_TABLE_COMPENSATION_PILOT', 'SEARCH_COMPENSATION']),
    }),
    getCompensation: builder.query({
      query: (id) => `/personal_reports/finance/pilot_compensation/${id}`,
      providesTags: (result, error) => useProvideTags(result, error, ['GET_COMPENSATION']),
    }),
    updateCompensation: builder.mutation({
      query: ({ id, data }) => ({
        url: `/personal_reports/finance/pilot_compensation/${id}`,
        body: data,
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: () => (['GET_TABLE_COMPENSATION_PILOT', 'SEARCH_COMPENSATION']),
    }),
    getCompensationFiles: builder.mutation({
      query: ({ id }) => ({
        url: `/personal_reports/finance/pilot_compensation_files/${id}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    searchReports: builder.query({
      query: ({ search, crew_id }) => `/personal_reports/finance/autocomplete/reports?search=${search}&crew_id=${crew_id}`,
      providesTags: (result, error) => useProvideTags(result, error, ['SEARCH_COMPENSATION']),
    }),
  }),
});

export const {
  useGetPersonalReportsTableQuery,
  useSendPersonalReportMutation,
  useDestroyPersonalReportMutation,
  useConvertPersonalCurrenciesMutation,
  useGetPersonalReportFilesMutation,
  useOpenPersonalReportFileMutation,
  useGetPersonalReportQuery,
  useUpdatePersonalReportMutation,
  useGetFinancePilotReportsTableQuery,
  useGetFinanceCrewProfileQuery,
  useGetFinanceCrewReportsTableQuery,
  useUpdatePersonalReportFinanceMutation,
  useAcceptPersonalReportFinanceMutation,
  useGetFinanceCrewCompensationsTableQuery,
  useDestroyCompensationMutation,
  useSendCompensationMutation,
  useUpdateCompensationMutation,
  useGetCompensationQuery,
  useGetCompensationFilesMutation,
  useSearchReportsQuery,
} = sessionApi;

export default personalReportsSlice.reducer;
